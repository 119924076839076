import React from "react"
import { graphql } from 'gatsby'

import NewsList from "./NewsList"
import ContentFilters from "../../components/ContentFilters"

import PageAttributes from "../../utils/PageAttributes"

class NewsArchive extends React.Component {
  constructor(props) {
    super(props)
    this.allNews = {}
    switch (this.props.pageContext.archiveType) {
      case `brand`:
        this.allNews = this.props.data.brand.news
        break
      case `office`:
        this.allNews = this.props.data.office.news
        break
      default:
        this.allNews = {}
        break
    }
  }

  ContentFiltersComponent = () => (
    <ContentFilters
      rootURL={`/news/`}
      indexType={`news`}
      offices={this.props.data.allOffices}
      archiveType={
        this.props.pageContext.archiveType
          ? this.props.pageContext.archiveType
          : ``
      }
    />
  )

  render() {
    return (
      <div>
        <PageAttributes attributes={this.props.pageAttributes} />
        <NewsList
          ContentFilters={this.ContentFiltersComponent}
          allNews={this.allNews}
        />
      </div>
    )
  }
}

NewsArchive.defaultProps = {
  pageAttributes: {
    bodyClass: `news-index--archive`,
    title: `News archive`,
    description: ``,
  },
}

export default NewsArchive

export const query = graphql`
  query NewsArchive($slug: String!) {
    brand: contentfulClients(slug: { eq: $slug }) {
      id
      node_locale
      brandName
      slug
      news {
        ...newsFragmentBase
      }
    }

    office: contentfulOffices(slug: { eq: $slug }) {
      id
      node_locale
      officeName
      slug
      news {
        ...newsFragmentBase
      }
    }

    allBrands: allContentfulClients(
      sort: { fields: [brandName], order: ASC }
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          ...brandFragmentNews
        }
      }
    }

    allOffices: allContentfulOffices(
      sort: { fields: [order], order: ASC }
      filter: { node_locale: { eq: "en-US" }, venture: { eq: false } }
    ) {
      edges {
        node {
          ...officeFragmentBase
        }
      }
    }
  }
`
